import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-mail-sended',
  templateUrl: './mail-sended.component.html',
  styleUrls: ['./mail-sended.component.scss'],
})
export class MailSendedComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private router: Router,
    public data: DataProvider,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-mail-reference';
    this.appService.selfLogout();
  }
}
