import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-bulk-register',
  templateUrl: './bulk-regist-error.component.html',
  styleUrls: ['./bulk-regist-error.component.scss'],
})
export class BulkRegisterErrorComponent   implements OnInit {
  public error_code = '';
  constructor(private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,
    public translate: TranslateService,) {}
  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-bulk-regist';
    this.error_code = this.route.snapshot.paramMap.get('code');
    this.translate.get('ERROR.' + this.error_code).subscribe(
      (res) => {
        document.getElementById('err_text').innerHTML = res;
        document.getElementById('err_text').style.display = 'block';
      }
    );
  }
  back(){
    this.router.navigate(['/contract-user-bulk-regist']);
  }
}
