<div class="main-padding">
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.REGISTER_USER' | translate }}</h3>

  <section class="content" id="info">
  <div class="card-body">
    <p class="left-border">{{ 'REGISTER_USER.SEND_VERIFICATION_CODE1' | translate }}</p>
    <p class="card-title">{{ 'REGISTER_USER.CHK_MISTAKES_CLICKYES_MSG' | translate }}</p>
  </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3">
          <h3 class="card-title"> {{ 'COMMON_WORDS.NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="card-title display">{{ User_Last_Name }}</h3>
             <h3 class="card-title" style="padding-left: 18px">
               {{ User_First_Name }}
             </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3">
          <h3 class="card-title"> {{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="content">{{Mail_Address}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3">
          <h3 class="card-title"> {{ 'COMMON_WORDS.USER_NUMBER' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="content"> {{Number}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3">
          <h3 class="card-title"> {{ 'COMMON_WORDS.USAGE_FORM' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="content" *ngIf="Use_Status == '1'">
                {{ 'REGISTRATION_LIST.NOT_MEMBER' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '2'">
                {{ 'REGISTRATION_LIST.ONGOING' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '3'">
                {{ 'REGISTRATION_LIST.RECESS' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '4'">
                {{ 'REGISTRATION_LIST.WITHDRAWAL' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON -->
    <div class="card-body">
      <div class="text-center" style="margin-top: 40px;">
        <p class="card-result">{{ 'REGISTER_USER.SEND_AUTH_EMAIL_MSG' | translate }}</p>
      </div>
      <div class="text-center" style="margin-top: 50px;">
        <div class="two-btn">
          <div class="display">
            <a (click)="back()" class="btn-grey">
              {{ 'BUTTON_TYPES.NO' | translate }}
            </a>
          </div>
          <div class="display">
            <a (click)="next()" class="btn-pink">
              {{ 'BUTTON_TYPES.YES' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
</section>
<section class="content" id="infoedit" style="display: none;">
  <div class="text text-center title_margintop">
    <h3 style="text-align: center; color: #000000">{{ 'REGISTER_USER.SENT_VERIFICATION_CODE' | translate }}</h3>
  </div>
  <div class="card-body">
    <div class="text-center">
      <p class="card-result">{{ 'EXAMINATION_CONFIRM.TITLE_CENTER_TEXT' | translate }}</p>
    </div>
  </div>

  <!-- BUTTON end -->
</section>
</div>
