<aside #mainSidebar class="main-sidebar sidebar-dark-primary elevation-4" id="main-sidebar">

  <!-- Sidebar -->
  <div class="sidebar">

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <li class="nav-item d-none d-sm-none d-md-none d-lg-block d-xl-block">
          <a id="sideMenu1" class="arrow" (click)="toggleMenuSidebar()" role="button">
            <i class="fas fa-bars"></i>
          </a>
        </li>
        <!-- ホーム -->
        <li class="nav-item"  *ngIf="data.menuHome">
          <a *ngIf="data.menuPageTitle == 'contract-home'" [routerLink]="['/']" (click)="checkMenu()"
              class="nav-link active" id="activeAdder-1">
            <i class="nav-icon icon-home"></i>
            <p class="side-text dropdownlist" id ='dropdownlist1'>{{ 'SIDEBAR_MENU.HOME' | translate }}
            </p>
          </a>
          <a *ngIf="data.menuPageTitle != 'contract-home'" [routerLink]="['/']" (click)="checkMenu()"
              class="nav-link" id="activeAdder-1">
            <i class="nav-icon icon-home"></i>
            <p class="side-text dropdownlist" id ='dropdownlist1'>{{ 'SIDEBAR_MENU.HOME' | translate }}
            </p>
          </a>
        </li>
        <!-- 法人情報 -->
        <li class="nav-item"  *ngIf="data.menuContractCorp">
          <a *ngIf="data.menuPageTitle == 'contract-corp-reference'" [routerLink]="['/contract-corp-reference']" (click)="checkMenu()"
              class="nav-link active" id="activeAdder-2">
            <i class="nav-icon icon-notif"></i>
            <p class="side-text dropdownlist" id ='dropdownlist2'>{{ 'SIDEBAR_MENU.COMPANY_INFO' | translate }}
            </p>
          </a>
          <a *ngIf="data.menuPageTitle != 'contract-corp-reference'" [routerLink]="['/contract-corp-reference']" (click)="checkMenu()"
              class="nav-link" id="activeAdder-2">
            <i class="nav-icon icon-notif"></i>
            <p class="side-text dropdownlist" id ='dropdownlist2'>{{ 'SIDEBAR_MENU.COMPANY_INFO' | translate }}
            </p>
          </a>
        </li>
        <!-- メールアドレス -->
        <li class="nav-item" *ngIf="data.menuContractMail">
          <a *ngIf="data.menuPageTitle == 'contract-mail-reference'" [routerLink]="['/contract-mail-reference']" (click)="checkMenu()"
              class="nav-link active" id="activeAdder-3">
            <i class="nav-icon icon-email"></i>
            <p class="side-text dropdownlist" id ='dropdownlist3'>{{ 'SIDEBAR_MENU.MAIL_ADDRESS' | translate }}
            </p>
          </a>
          <a *ngIf="data.menuPageTitle != 'contract-mail-reference'" [routerLink]="['/contract-mail-reference']" (click)="checkMenu()"
              class="nav-link" id="activeAdder-3">
            <i class="nav-icon icon-email"></i>
            <p class="side-text dropdownlist" id ='dropdownlist3'>{{ 'SIDEBAR_MENU.MAIL_ADDRESS' | translate }}
            </p>
          </a>
        </li>
        <!-- 利用者の登録 -->
        <!-- <li class="nav-item" *ngIf="data.menuContractUser">
          <a *ngIf="data.menuPageTitle == 'contract-user-regist'" [routerLink]="['/contract-user-regist']" (click)="checkMenu()"
              class="nav-link active" id="activeAdder-4">
            <i class="nav-icon icon-pencil"></i>
            <p class="side-text dropdownlist" id ='dropdownlist1'>{{ 'SIDEBAR_MENU.REGISTER_USER' | translate }}
            </p>
          </a>
          <a *ngIf="data.menuPageTitle != 'contract-user-regist'" [routerLink]="['/contract-user-regist']" (click)="checkMenu()"
              class="nav-link" id="activeAdder-4">
            <i class="nav-icon icon-pencil"></i>
            <p class="side-text dropdownlist" id ='dropdownlist1'>{{ 'SIDEBAR_MENU.REGISTER_USER' | translate }}
            </p>
          </a>
        </li> -->
        <!-- 利用者の一括登録 -->
        <!-- <li class="nav-item" *ngIf="data.menuContractUserBulk">
          <a *ngIf="data.menuPageTitle == 'contract-user-bulk-regist'" [routerLink]="['/contract-user-bulk-regist']" (click)="checkMenu()"
              class="nav-link active" id="activeAdder-5">
            <i class="nav-icon icon-upload"></i>
            <p class="side-text dropdownlist" id ='dropdownlist1'>{{ 'SIDEBAR_MENU.REGISTER_ALL_USER' | translate }}
            </p>
          </a>
          <a *ngIf="data.menuPageTitle != 'contract-user-bulk-regist'" [routerLink]="['/contract-user-bulk-regist']" (click)="checkMenu()"
              class="nav-link" id="activeAdder-5">
            <i class="nav-icon icon-upload"></i>
            <p class="side-text dropdownlist" id ='dropdownlist1'>{{ 'SIDEBAR_MENU.REGISTER_ALL_USER' | translate }}
            </p>
          </a>
        </li> -->
        <!-- 登録者一覧 -->
        <li class="nav-item" *ngIf="data.menuContractUserList">

          <a *ngIf="data.menuPageTitle == 'contract-user-list'" [routerLink]="['/contract-user-list']" (click)="checkMenu()"
              class="nav-link active" id="activeAdder-6">
            <i class="nav-icon icon-book"></i>
            <p class="side-text dropdownlist" id ='dropdownlist4'>{{ 'SIDEBAR_MENU.REGISTRATION_LIST' | translate }}
            </p>
          </a>
          <a *ngIf="data.menuPageTitle != 'contract-user-list'" [routerLink]="['/contract-user-list']" (click)="checkMenu()"
              class="nav-link" id="activeAdder-6">
            <i class="nav-icon icon-book"></i>
            <p class="side-text dropdownlist" id ='dropdownlist4'>{{ 'SIDEBAR_MENU.REGISTRATION_LIST' | translate }}
            </p>
          </a>
        </li>
        <!-- 認証番号 一括送信 -->
        <!-- <li class="nav-item" *ngIf="data.menuContractBulk">
          <a *ngIf="data.menuPageTitle == 'contract-bulk-authmail'" [routerLink]="['/contract-bulk-authmail']" (click)="checkMenu()"
              class="nav-link active" id="activeAdder-7">
            <i class="nav-icon icon-flag"></i>
            <p class="side-text dropdownlist" id ='dropdownlist1'>{{ 'SIDEBAR_MENU.ALL_VERIFICATION_CODE_SEND' | translate }}
            </p>
          </a>
          <a *ngIf="data.menuPageTitle != 'contract-bulk-authmail'" [routerLink]="['/contract-bulk-authmail']" (click)="checkMenu()"
              class="nav-link" id="activeAdder-7">
            <i class="nav-icon icon-flag"></i>
            <p class="side-text dropdownlist" id ='dropdownlist1'>{{ 'SIDEBAR_MENU.ALL_VERIFICATION_CODE_SEND' | translate }}
            </p>
          </a>
        </li> -->
        <!-- 未払金支払 -->
        <!-- <li class="nav-item" *ngIf="data.menuContractUnpaid">
          <a *ngIf="data.menuPageTitle == 'contract-unpaid-payment'" [routerLink]="['/contract-unpaid-payment']" (click)="checkMenu()"
              class="nav-link active" id="activeAdder-7">
            <i class="nav-icon icon-flag"></i>
            <p class="side-text dropdownlist" id ='dropdownlist1'>{{ 'SIDEBAR_MENU.CONTRACT_UNPAID_PAYMENT' | translate }}
            </p>
          </a>
          <a *ngIf="data.menuPageTitle != 'contract-unpaid-payment'" [routerLink]="['/contract-unpaid-payment']" (click)="checkMenu()"
              class="nav-link" id="activeAdder-7">
            <i class="nav-icon icon-flag"></i>
            <p class="side-text dropdownlist" id ='dropdownlist1'>{{ 'SIDEBAR_MENU.CONTRACT_UNPAID_PAYMENT' | translate }}
            </p>
          </a>
        </li> -->
        <!-- コード管理 -->
        <li class="nav-item" *ngIf="data.menuOrderApply || data.menuOrderList || data.menuCodelist">
          <a
            id="apply1"
            class="nav-link"
            style="cursor: pointer"
            (click)="collapseApply()"
          >
            <i class="nav-icon icon-notebook"></i>
            <p class="side-text" id="apply1-text">
              {{ 'SIDEBAR_MENU.CONTRACT_ORDER' | translate }}
            </p>
          </a>
          <ul
            class="nav nav-pills nav-sidebar flex-column treeview"
            style="display: none"
            id="applyView"
          >
            <!-- 1 -->
            <li class="nav-item" *ngIf="data.menuOrderApply">
              <a
                *ngIf="data.menuPageTitle == 'contract-order-apply'"
                [routerLink]="['/contract-order-apply']"
                class="nav-link active"
                (click)="checkMenuDropDownList(1)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist1">
                  {{ 'SIDEBAR_MENU.CONTRACT_ORDER_APPLY' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'contract-order-apply'"
                [routerLink]="['/contract-order-apply']"
                class="nav-link"
                (click)="checkMenuDropDownList(1)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist1">
                  {{ 'SIDEBAR_MENU.CONTRACT_ORDER_APPLY' | translate }}
                </p>
              </a>
            </li>
            <!-- 2 -->
            <li class="nav-item" *ngIf="data.menuOrderList">
              <a
                *ngIf="data.menuPageTitle == 'contract-order-list'"
                [routerLink]="['/contract-order-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(1)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist2">
                  {{ 'SIDEBAR_MENU.CONTRACT_ORDER_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'contract-order-list'"
                [routerLink]="['/contract-order-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(1)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist2">
                  {{ 'SIDEBAR_MENU.CONTRACT_ORDER_LIST' | translate }}
                </p>
              </a>
            </li>
            <!-- 3 -->
            <li class="nav-item" *ngIf="data.menuCodelist">
              <a
                *ngIf="data.menuPageTitle == 'contract-code-list'"
                [routerLink]="['/contract-code-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(1)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist3">
                  {{ 'SIDEBAR_MENU.CONTRACT_CODE_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'contract-code-list'"
                [routerLink]="['/contract-code-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(1)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist3">
                  {{ 'SIDEBAR_MENU.CONTRACT_CODE_LIST' | translate }}
                </p>
              </a>
            </li>
            <!-- 4 -->
            <li class="nav-item" *ngIf="data.menuTicketlist">
              <a
                *ngIf="data.menuPageTitle == 'contract-ticket-list'"
                [routerLink]="['/contract-ticket-list']"
                class="nav-link active"
                (click)="checkMenuDropDownList(1)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist4">
                  {{ 'SIDEBAR_MENU.CONTRACT_TICKET_LIST' | translate }}
                </p>
              </a>
              <a
                *ngIf="data.menuPageTitle != 'contract-ticket-list'"
                [routerLink]="['/contract-ticket-list']"
                class="nav-link"
                (click)="checkMenuDropDownList(1)"
              >
                <i *ngIf="sidebarMenuOpened" class="icon-arrow-right left40px"></i>
                <i *ngIf="!sidebarMenuOpened" class="icon-arrow-right left7px"></i>
                <p class="side-text dropdownlist" id="dropdownlist4">
                  {{ 'SIDEBAR_MENU.CONTRACT_TICKET_LIST' | translate }}
                </p>
              </a>
            </li>
          </ul>
        </li>
        <!-- ログアウト -->
        <li class="nav-item">
          <a (click)="logout()" class="nav-link" style="cursor: pointer;" title="ログアウト">
            <i class="nav-icon fas fa-sign-out-alt" style="display: initial;"></i>
            <p class="side-text ">{{ 'SIDEBAR_MENU.LOGOUT' | translate }}</p>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</aside>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>