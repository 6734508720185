import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-list-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ViewsErrorComponent implements OnInit {

  public error_code;
  public user_id;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,) {}

  ngOnInit() {
    this.data.menuPageTitle = '';
    this.error_code = this.route.snapshot.paramMap.get('code');
    this.user_id = this.route.snapshot.paramMap.get('user_id');
    if(!this.error_code){
      document.getElementById('err_title').style.display = 'block';
    } else {
      this.translate.get('ETITLE.' + this.error_code).subscribe((res) => (document.getElementById('err_title').innerHTML = res));
      document.getElementById('err_title').style.display = 'block';
      this.translate.get('ERROR.' + this.error_code).subscribe(
        (res) => {
        var user_id = /社員番号orID/gi; 
        var error_str = res.replace(user_id, this.user_id);
          document.getElementById('err_text').innerHTML = error_str + '<br>' + '('+this.error_code+')'
          document.getElementById('err_text').style.display = 'block';
        }
      );
    }
    this.widthChanger('main-header', 762);
    this.widthChanger('content-wrapper', 762);
  }
  
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
}
