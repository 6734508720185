import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-email',
  templateUrl: './mail-reference.component.html',
  styleUrls: ['./mail-reference.component.scss'],
})
export class MailReferenceComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  public email;
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router,
    public data: DataProvider
  ) {}

  ngOnInit() {
    this.registerForm = new FormGroup({
      email: new FormControl(null, Validators.required)
    });
    this.data.menuPageTitle = 'contract-mail-reference';
    this.email = this.data.loggedEmail;
  }

  // warning() {
  //     this.router.navigate(['/warning']);
  // }

  ngOnDestroy() {
  }
  change(){
    this.router.navigate(['/contract-mail-edit']);
  }
}
