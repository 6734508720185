<div class="main-padding">
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.MAIL_ADDRESS' | translate }}</h3>
  <div class="card-body">
    <p class="left-border">{{ 'MAIL_ADDRESS.MAIL_ADDRESS_REFERENCE' | translate }}</p>
    <p class="card-title">{{ 'MAIL_ADDRESS.REGISTERED_MAIL_MSG' | translate }}</p>
  </div>
  <!-- Section 1 -->
  <section class="content" id="info">
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3">
          <h3 class="card-title">{{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-3 col-xl-3 display">
            </div>
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="content"> {{email}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- button -->
    <div class="card-body">
      <div class="text-center">
        <div class="col-12">
          <div class="display">
            <a (click)="change()" class="btn-pink">
              <i></i> {{ 'BUTTON_TYPES.CHANGE' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- button end -->
  </section>
<!-- section end -->
</div>