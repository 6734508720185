<div style="position: absolute; top: 0; left: 0; width: 100%">
  <nav
    class="navbar navbar-expand navbar-white navbar-light"
    style="margin-left: 0px"
  >
    <a [routerLink]="['/wf-login']">
      <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
    /></a>
  </nav>
</div>
<div class="login-box">
  <div class="login-logo">
    <i class="warning"></i>
    <p id="err_title1" class="error-text">
      {{ 'ERROR.TEXT' | translate }}
    </p>
  </div>
  <div class="text-center" id="err_text1">
    <p id="err_text1" class="error-text-black"> {{ 'ERROR.ED00101_TEXT_1' | translate }}</p>
    <p class="error-text-black"> {{uuid}}</p>
    <p id="err_text2" class="error-text-black"> {{ 'ERROR.ED00101_TEXT_2' | translate }}</p>
  </div>
</div>
