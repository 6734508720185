import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-unpaid-payment-conf',
  templateUrl: './unpaid-payment-conf.component.html',
  styleUrls: ['./unpaid-payment-conf.component.scss'],
})
export class UnpaidPaymentConfirmComponent implements OnInit {
  public Casio_Id = '';
  public User_No = '';
  public User_Name = '';
  public Unpaid_Amount = '';
  public End_Date = '';

  constructor(private router: Router,
    private route: ActivatedRoute,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.data.menuPageTitle = 'contract-unpaid-payment';
    this.Casio_Id = this.route.snapshot.paramMap.get('casio_id');
    this.User_No = this.route.snapshot.paramMap.get('user_no');
    this.User_Name = this.route.snapshot.paramMap.get('user_name');
    this.Unpaid_Amount = this.route.snapshot.paramMap.get('unpaid_amount');
    this.End_Date = this.route.snapshot.paramMap.get('end_date');
  }
  back() {
    this.router.navigate(['/contract-unpaid-payment']);
  }
  payment() {
    this.spinner.show();
    const body = {
      data: {
        user_uuid: this.data.uuid,
        casio_id: this.Casio_Id
      }
    };
    console_log(body);
    this.rest.Unpaid_User_Payment(this.data.uuid, this.Casio_Id).subscribe(
      (res) => {
        console_log(res);
        if (res.code == 'S000001') {
          if (res.data !== null) {
            const html = `<html><head><meta name="robots" content="none"><meta charset="SHIFT_JIS"></head><body>${decodeURIComponent(
              res.data.html
            )}</body><script>document.forms[0].submit();</script></html>`;
            const x = window.open('', '_self');
            if (x != null) {
              x.document.open();
              x.document.write(html);
              x.document.close();
            }
          }
        }
        this.spinner.hide();
        // this.router.navigate(['/contract-unpaid-payment-comp']);
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', { code: err.error.error.code }]);
      }
    )
  }
}
