import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-list-edit',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class BulkAuthmailErrorComponent implements OnInit {
  public ErrorData = [];
  constructor(private router: Router,
    private route: ActivatedRoute,
    public data: DataProvider,
    public rest: CallapiService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-bulk-authmail';
    this.spinner.show();
    
    this.ErrorData = JSON.parse(sessionStorage.getItem('WF00701')).data.error_data,
    this.spinner.hide();
    this.widthChanger('main-header', 915);
    this.widthChanger('content-wrapper', 915);
    sessionStorage.clear();
  }

  back(){
    this.router.navigate(['/contract-bulk-authmail']);
  }
  
  
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
}
