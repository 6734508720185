<div class="main-padding">
  <h3 class="header-bottom">
    {{ 'CONTRACT_UNPAID_PAYMENT.TITLE' | translate }}
  </h3>
  <section class="content" id="info">
    <div class="card-body" >
      <div class="title_margintop">
        <h3 style="text-align: center; color: black;">{{ 'CONTRACT_UNPAID_PAYMENT.PAYMENT_IS_COMPLETE' | translate }}</h3>
      </div>
      <div class="text-center" style="margin-top: 40px;">
        <a (click)="back()" class="btn-grey">
          {{ 'BUTTON_TYPES.BACK' | translate }}
        </a>
      </div>
    </div>
  </section>
</div>