import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { console_log, DataProvider } from 'src/providers/data/data';
import { TranslateService } from '@ngx-translate/core';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public registerForm: FormGroup;
  public User_Last_Name;
  public User_First_Name;
  public Mail_Address;
  public User_Id_1: string = '';

  constructor(private router: Router,
    public data: DataProvider,
    private translate: TranslateService,
    public rest: CallapiService,
    private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.widthChanger('main-header', 915);
    this.widthChanger('content-wrapper', 915);
    this.data.menuPageTitle = 'contract-user-regist';
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
  dataValidation() {
    let err = false;
    let errTxt = '';
    if (this.User_Id_1 == null || this.User_Id_1 == '') {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_EMPLOYEE_NUMBER_CORP_A')
        .subscribe((res) => (errTxt = res));
    }
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (this.Mail_Address == null || this.Mail_Address == '' || this.Mail_Address == undefined) {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_MAIL_ADDRESS')
        .subscribe((res) => (errTxt = res));
    }else if (this.Mail_Address != "" && (this.Mail_Address.length <= 5 || !EMAIL_REGEXP.test(this.Mail_Address))) {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_MAIL_ADDRESS')
        .subscribe((res) => (errTxt = res));
    }
    if (this.User_First_Name == null || this.User_First_Name == '') {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_USER_FIRST_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.User_Last_Name == null || this.User_Last_Name == '') {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_USER_LAST_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('errormsgsett').innerHTML = errTxt;
      document.getElementById('errormsgsett').style.display = 'block';
      return false;
    }
    return true;
  }
  input(){
    const body = {
      data: {
        user_uuid: this.data.uuid,
        corporation_no: JSON.parse(localStorage.getItem('loginData')).data.corporation_no,
        user_last_name: this.User_Last_Name,
        user_first_name: this.User_First_Name,
        mail_address: this.Mail_Address,
        user_id_1: this.User_Id_1,
      },
    }
    console_log(body);
    if (!this.dataValidation()) {
      return true;
    } else {
      document.getElementById('confirm').style.display='block';
      document.getElementById('input').style.display='none';
    }
  }
  finish(){
    this.spinner.show();
    this.rest.Create_Members(
      this.data.uuid, 
      JSON.parse(localStorage.getItem('loginData')).data.corporation_no,
      this.User_Last_Name,
      this.User_First_Name,
      this.Mail_Address,
      this.User_Id_1).subscribe(
        (res)=>{
          console_log(res);
          this.router.navigate(['/contract-user-regist-comp',{user_code: res.data.user_code}]);
          this.spinner.hide();
        },
        (err)=>{
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/contract-err', {code: err.error.error.code}]);
        }
    );
  }
  back(){
    document.getElementById('confirm').style.display='none';
    document.getElementById('input').style.display='block';
  }
  CharCode(){
    if(this.User_Id_1.indexOf('-') > -1) {
      this.User_Id_1 = this.User_Id_1.replace(/-/gi, '');
    }
  }
}
