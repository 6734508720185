import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/ja';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-code-list',
  templateUrl: './code-list.component.html',
  styleUrls: ['./code-list.component.scss'],
})

export class CodeListComponent implements OnInit {
  public order_id = '';
  public corporation_name = '';
  public coupon_create_date_month = '';
  public coupon_code_1 = '';
  public coupon_code_2 = '';
  public coupon_code_3 = '';
  public order_staff = '';
  public coupon_unused = false;
  public coupon_use = false;
  public coupon_used = false;
  public coupon_expired = false;
  public coupon_revoked = false;

  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'contract-code-list';
    this.Search();
    this.widthChanger('main-header', 360);
  }

  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  Search() {
    this.spinner.show();
    this.rest.Get_CouponCode(
      this.data.uuid,
      this.data.scope,
      this.order_id,
      this.corporation_name,
      this.datepipe.transform(this.coupon_create_date_month, 'yyyy-MM'),
      this.coupon_code_1,
      this.coupon_code_2,
      this.coupon_code_3,
      this.order_staff,
      this.coupon_unused ? '1' : '',
      this.coupon_use ? '1' : '',
      this.coupon_used ? '1' : '',
      this.coupon_expired ? '1' : '',
      this.coupon_revoked ? '1' : '',
    ).subscribe(
      (res)=>{
        console_log(res);
        this.filteredItems = res.Coupons;
        if (res.code == 'W000001') {
          document.getElementById('limit500').style.display = 'block';
        }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.spinner.hide();
        this.refreshItems();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }

  details(coupon_code) {
    this.router.navigate(['/contract-ticket-list',  {coupon_code: coupon_code}]);
  }

  pdf_download(uuid) {
  }

  csvOutput(){
    this.spinner.show();
    this.rest.Create_CouponsCsvFile(
      this.data.uuid,
      this.data.scope,
      this.order_id,
      this.corporation_name,
      this.datepipe.transform(this.coupon_create_date_month, 'yyyy-MM'),
      this.coupon_code_1,
      this.coupon_code_2,
      this.coupon_code_3,
      this.order_staff,
      this.coupon_unused ? '1' : '',
      this.coupon_use ? '1' : '',
      this.coupon_used ? '1' : '',
      this.coupon_expired ? '1' : '',
      this.coupon_revoked ? '1' : '',
    ).subscribe(
      (res) => {
        console_log(res);
        const link = document.createElement('a');
        link.href = res.data.s3url;
        link.click();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }

  @ViewChild('date1') date1: ElementRef;

  resetDate(id) {
    console_log(id)
    if(id == 1){
      if(this.coupon_create_date_month != ''){
        this.date1['displayValue'] = '';
        this.coupon_create_date_month = '';
        console_log(this.coupon_create_date_month)
        if(this.coupon_create_date_month == ''){
          document.getElementById('clear1').style.display = 'none';
          document.getElementById('calendar1').style.display = 'block';
        }
      }
    }
  }

  Calendar(id){
    if(id == 1){
      if(this.coupon_create_date_month != ''){
        document.getElementById('clear1').style.display = 'block';
        document.getElementById('calendar1').style.display = 'none';
        console_log(this.coupon_create_date_month);
      }else{
        document.getElementById('clear1').style.display = 'none';
        document.getElementById('calendar1').style.display = 'block';
        console_log(this.coupon_create_date_month);
      }
    }
  }

  options: DatepickerOptions = {
    minYear: getYear(new Date()) - 30, // minimum available and selectable year
    maxYear: getYear(new Date()) + 30, // maximum available and selectable year
    placeholder: '発行月', // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: 'yyyy-MM', // date format to display in input
    formatTitle: 'yyyy'+'年'+'MM'+'月',
    formatDays: 'EEEEE',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: locale, // date-fns locale
    position: 'bottom',
    inputClass: '', // custom input CSS class to be applied
    calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
    scrollBarColor: '#E10800', // in case you customize you theme, here you define scroll bar color
  };

  parse(e) {
    return parseFloat(e).toLocaleString('en');
  }
}