<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'TICKET_LIST.TITLE' | translate }}
  </h3>
  <div class="card-body search-form-top">
    <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="search_by_code"
              name="search_flg" (click)="searchFLG(1)"
            />
            <label for="search_by_code" class="custom-control-label ftext">{{'TICKET_LIST.SEARCH_BY_CODE' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="search_by_name"
              name="search_flg" (click)="searchFLG(2)"
            />
            <label for="search_by_name" class="custom-control-label ftext">{{'TICKET_LIST.SEARCH_BY_NAME' | translate}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="search_flg == 1">
      <div class="input-group code">
        <input
          type="text"
          class="form-control"
          id="coupon_code_1"
          name="coupon_code_1"
          [(ngModel)]="coupon_code_1"
          placeholder="{{
            'CODE_LIST.CODE_PLACEHOLDER' | translate
          }}"
          maxlength="4"
        />
      </div>
      <div class="minus">ー</div>
      <div class="input-group code">
        <input
          type="text"
          class="form-control"
          id="coupon_code_2"
          name="coupon_code_2"
          [(ngModel)]="coupon_code_2"
          placeholder="{{
            'CODE_LIST.CODE_PLACEHOLDER' | translate
          }}"
          maxlength="4"
        />
      </div>
      <div class="minus">ー</div>
      <div class="input-group code">
        <input
          type="text"
          class="form-control"
          id="coupon_code_3"
          name="coupon_code_3"
          [(ngModel)]="coupon_code_3"
          placeholder="{{
            'CODE_LIST.CODE_PLACEHOLDER' | translate
          }}"
          maxlength="4"
        />
      </div>
    </div>
    <div class="row" *ngIf="search_flg == 2">
      <div class="input-group company-name">
        <input 
        type="text" 
        class="form-control"
        id="ticket_user_name"
        [(ngModel)]="ticket_user_name"
        placeholder="{{ 'TICKET_LIST.NAME' | translate }}"
        >
      </div>
    </div>
    <div class="btn-search-top">
      <a target="_blank" class="btn-search" (click)="Search()" [ngClass]="{'btn-search-disable': SearchCheck()}"
        >{{ 'CODE_LIST.SEARCH_BUTTON' | translate }}
        <img
          class="search-icon"
          src="assets/icons/srch.svg"
        />
      </a>
    </div>
    <!-- Limit 10000 text -->
    <label class="limit-500" id="limit10000">
      {{'CODE_LIST.SEARCH_LIMIT_10000' | translate}}
    </label>
    <!-- Radio -->
    <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="r1"
              name="customRadio" (click)="selectedRadios(10)"
            />
            <label for="r1" class="custom-control-label ftext">{{
              'COMMON.CASE_10' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r2"
              name="customRadio" (click)="selectedRadios(20)"
            />
            <label for="r2" class="custom-control-label ftext">{{
              'COMMON.CASE_20' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r3"
              name="customRadio" (click)="selectedRadios(50)"
            />
            <label for="r3" class="custom-control-label ftext">{{
              'COMMON.CASE_50' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r4"
              name="customRadio" (click)="selectedRadios(100)"
            />
            <label for="r4" class="custom-control-label ftext">{{
              'COMMON.CASE_100' | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- Section -->
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width">
          <table class="table table-bordered" style="min-width: 800px;">
            <thead>
              <tr class="text-center">
                <th class="ftext">
                  {{ 'TICKET_LIST.CODE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.ACQUISITION_DATE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.EXPIRATION_DATE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.STATUS' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.USE_DATE' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.STORE_USED' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.NAME' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.NAME_KANA' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.NAME_OF_RECIPIENT' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.NAME_OF_RECIPIENT_KANA' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.GENDER' | translate }}
                </th>
                <th class="ftext">
                  {{ 'TICKET_LIST.AGE' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of items" class="text-center">
                <td class="tbtext">{{ list.coupon_code }}</td>
                <td class="tbtext">{{ list.ticket_create_date }}</td>
                <td class="tbtext">{{ list.ticket_expire_date }}</td>
                <td class="tbtext">{{ list.ticket_status }}</td>
                <td class="tbtext">{{ list.ticket_used_date }}</td>
                <td class="tbtext">{{ list.club_name }}</td>
                <td class="tbtext">{{ list.name }}</td>
                <td class="tbtext">{{ list.name_kana }}</td>
                <td class="tbtext">{{ list.transfer_name }}</td>
                <td class="tbtext">{{ list.transfer_name_kana }}</td>
                <td class="tbtext">{{ list.sex }}</td>
                <td class="tbtext">{{ list.age }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="filteredItems.length >= 1">
          <label class="pages">{{'COMMON.PAGE' | translate}} {{ currentIndex }}/{{ pageNumber }}</label>
        </div>          
        <div class="page-point" *ngIf="filteredItems.length >= 1">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;</span
          >
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          ></span>
          <span
            [ngClass]="{
              disabled: currentIndex == pageNumber || pageNumber == 0
            }"
            (click)="nextPage()"
            class="next"
            >&raquo;</span
          >
        </div>
        <div class="text-center" style="margin-top: 16px;max-width: 1500px;">
          <div class="col-12">
            <div class="display">
              <a (click)="csvOutput()" class="btn-search btn-csv csv-font" [ngClass]="{'btn-search-disable': SearchCheck()}">
                {{'COMMON.CSV' | translate}}
              </a>
              <i class="fa fa-print"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>