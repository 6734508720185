<div class="main-padding">
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.REGISTRATION_LIST' | translate }}</h3>
  <section class="content" id="userdeleteinfo">
    <div class="card-body">
      <p class="left-border">{{ 'REGISTRATION_LIST.DELETE_USERINFO' | translate }}</p>
      <p class="card-title">{{ 'REGISTRATION_LIST.USER_INFO_WILL_DELETE_MSG' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3">
          <h3 class="card-title"> {{ 'COMMON_WORDS.NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
               <h3 class="card-title display">{{ User_Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ User_First_Name }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3">
          <h3 class="card-title"> {{ 'COMMON_WORDS.MAIL_ADDRESS' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="content">{{Mail_Address}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3">
          <h3 class="card-title"*ngIf="Type == 'A'"> {{ 'COMMON_WORDS.USER_NUMBER' | translate }}</h3>
          <h3 class="card-title"*ngIf="Type == 'B'"> {{ 'REGISTER_USER.ID_DEST' | translate }}</h3>
          <h3 class="card-title"*ngIf="Type == 'C'">  {{ 'COMMON_WORDS.NUMBER' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-9 col-xl-9 display">
              <h3 class="content"> {{Number}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3 col-xl-3">
          <h3 class="card-title"> {{ 'COMMON_WORDS.USAGE_FORM' | translate }}</h3>
        </div>
        <div class="col-12 col-md-5 col-xl-5">
          <div class="col-12 display">
            <div class="col-12 col-md-12 col-xl-12 display">
              <h3 class="content" *ngIf="Use_Status == '1'">
                {{ 'REGISTRATION_LIST.NOT_MEMBER' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '2'">
                {{ 'REGISTRATION_LIST.ONGOING' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '3'">
                {{ 'REGISTRATION_LIST.RECESS' | translate }}
              </h3>
              <h3 class="content" *ngIf="Use_Status == '4'">
                {{ 'REGISTRATION_LIST.WITHDRAWAL' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- BUTTON -->
    <div class="col-12 text-center">
      <p class="text text-line"> {{ 'REGISTRATION_LIST.IF_DELETE_CLICK_YES_MSG1' | translate }}<br>{{ 'REGISTRATION_LIST.IF_DELETE_CLICK_YES_MSG2' | translate }} </p>
      <div class="custom-control custom-checkbox">
        <label for="vehicle3" class="radiochecklbl_margintop"> {{ 'REGISTRATION_LIST.ALLOW' | translate }}</label>
          <input  class="custom-control-input
                  custom-control-input-danger
                  custom-control-input-outline"
                  type="checkbox"
                  id="customCheckbox1"
                  [(ngModel)]="Under">
          <label for="customCheckbox1" style="margin-left: 40px;" class="custom-control-label ftext"></label>
      </div>
    </div>
     <!-- <label id ="chklabel" for="vehicle3"> 了承しました</label>
            <input type="checkbox" class="largerCheckbox custom-control" id="vehicle3" name="vehicle3" value="Boat"> -->

    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back()" class="btn-grey">
            <i></i> {{ 'BUTTON_TYPES.BACK' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="Delete()" class="btn-pink">
            <i></i> {{ 'BUTTON_TYPES.YES' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- BUTTON end -->
  </section>

  <section class="content" id="userdeletefinish" style="display: none;">
    <div class="title_margintop">
      <h3 style="color: black; text-align: center;">{{ 'REGISTRATION_LIST.DELETE_COMPLETED' | translate }}</h3>
    </div>
    <div class="text-center">
      <a (click)="list()" class="btn-grey1">
        <i></i> {{ 'BUTTON_TYPES.BACK_TO_REGISTRATION_LIST' | translate }}
      </a>
    </div>
    <!-- BUTTON end -->
  </section>

</div>
