import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-bulk-regist-comp',
  templateUrl: './bulk-regist-comp.component.html',
  styleUrls: ['./bulk-regist-comp.component.scss'],
})
export class BulkRegisterCompComponent  implements OnInit {

  constructor(private router: Router,
    public data: DataProvider) {}
  
  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-bulk-regist';
  }
  back(){
    this.router.navigate(['/contract-user-bulk-regist']);
  }
  authmail(){
    this.router.navigate(['/contract-bulk-authmail']);
  }
  changeuserinfo(){
    this.router.navigate(['/contract-user-list']);
  }
}
