<section>
  <div class="main-padding">
  <!-- Section 1 -->
  <section class="content" id="info">
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <p class="subtitleText">{{ 'HOME.HOME_TITLE_1' | translate }}</p>
      </div>
      <div class="row notice-home">
        <p class="notice" *ngFor="let list of Notice_Data">{{list.date}}&nbsp;&nbsp;{{list.content}}</p>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row d-flex">
        <a class="btn-pinkmini mr-3" (click)="ManualPDF()"><img src="assets/icons/redbook.svg"/> {{ 'HOME2.MANUAL' | translate }}</a>
        <a class="btn-pinkmini" (click)="TermsPDF()"><img src="assets/icons/redbook.svg"/> {{ 'HOME2.TERMS' | translate }}</a>
      </div>
      <div class="row">
        <p class="subtitleText" style="margin-top: 22px;">{{ 'HOME.HOME_TITLE_2' | translate }}</p>
      </div>
      <div class="row notice-home"  style="padding-bottom: 1px;display: block;">
        <p class="detail1"  *ngIf="Corporation_Form == '1'">{{ 'HOME.HOME_TEXT_1' | translate }} <br>  {{'COMPANY_INFO.CONTRACTED_COMPANY_EMPLOYEE' | translate}} </p>
        <p class="detail1"  *ngIf="Corporation_Form == '2'">{{ 'HOME.HOME_TEXT_1' | translate }} <br> {{'COMPANY_INFO.HEALTH_INSURANCE' | translate}} </p>
        <p class="detail1"  *ngIf="Corporation_Form == '3'">{{ 'HOME.HOME_TEXT_1' | translate }} <br> {{'COMPANY_INFO.WElFARE_AGENCY' | translate}} </p>

        <p class="detail1" *ngIf="(Corporation_Form == '1' ||  Corporation_Form == '2') && PromoCode">
          {{'DETAILS.PROMO_CODE' | translate }} <br>
         {{promotion_code1}}
        </p>
        <p class="detail1" *ngIf="Corporation_Form == '3' && PromoCode">
          {{'DETAILS.PROMO_CODE' | translate }}① <br>(月会費：6,578円,利用可能店舗：JOYFIT,JOYFIT24) <br>
          {{promotion_code1}} <br>
          {{'DETAILS.PROMO_CODE' | translate }}② <br>(月会費：9,878円,利用可能店舗：JOYFIT,JOYFIT24,JOYFIT YOGA) <br>
          {{promotion_code2}}
        </p>
      </div>
    </div>
  </section>
</div>
</section>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>