import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-authmail-configure',
  templateUrl: './configure.component.html',
  styleUrls: ['./configure.component.scss'],
})
export class AuthmailConfigureComponent implements OnInit {
  
  public User_Last_Name;
  public User_First_Name;
  public Number;
  public Mail_Address;
  public User_Id_1;
  public Use_Status;
  public User_Code;
  public Confirm_Flg;
  constructor(private router: Router,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    public data: DataProvider,
    private route: ActivatedRoute,) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-regist';
    this.User_Code = this.route.snapshot.paramMap.get('user_code');
    this.spinner.show();
    this.rest.Get_MembersId(this.data.uuid, this.User_Code).subscribe(
      (res)=>{
        console_log(res);
        this.User_Last_Name = res.data.user_last_name;
        this.User_First_Name = res.data.user_first_name;
        this.Mail_Address = res.data.mail_address;
        this.Number = res.data.user_no;
        this.Use_Status = res.data.use_status;
        this.Confirm_Flg = res.data.confirm_flg;
        this.spinner.hide();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }
  back(){
    this.router.navigate(['/contract-user-regist-comp', {user_code: this.User_Code}]);
  }
  next(){
    this.spinner.show();
    this.rest.Send_AuthMail(this.data.uuid, this.User_Code).subscribe(
      (res)=>{
        console_log(res);
        document.getElementById('infoedit').style.display='block';
        document.getElementById('info').style.display='none';
        this.spinner.hide();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }
}
