import { Injectable } from "@angular/core";
import { USER_POOL_ID, CLIENT_ID, environment } from '../../environments/environment';

@Injectable()
export class DataProvider {
    public menuPageTitle: string = "home";
    public sidebarMenuOpened: boolean = true;
    public canLogin: boolean = false;
    public uuid: string = "";
    public loginError: string = "";
    public loggedUsername: string = "";
    public loggedEmail: string = "";
    public loggedPassword: string = "";
    public globalLoading: boolean = false;
    public poolData = {
        UserPoolId: USER_POOL_ID,
        ClientId: CLIENT_ID
    };
    public menuHome: boolean = false;
    public menuHomePage: string = '';
    public menuContractCorp: boolean = false;
    public menuContractCorpPage: string = '';
    public menuContractMail: boolean = false;
    public menuContractMailPage: string = '';
    public menuContractUser: boolean = false;
    public menuContractUserPage: string = '';
    public menuContractUserBulk: boolean = false;
    public menuContractUserBulkPage: string = '';
    public menuContractUserList: boolean = false;
    public menuContractUserListPage: string = '';
    public menuContractBulk: boolean = false;
    public menuContractBulkPage: string = '';
    public menuContractUnpaid: boolean = false;
    public menuContractUnpaidPage: string = '';
    public menuOrderApply: boolean = false;
    public menuOrderList: boolean = false;
    public menuCodelist: boolean = false;
    public scope = '2';
    public stub = '';
    constructor() {}
}
export function console_log(res){
    if(!environment.production) {
        console.log(res);
    }
}