<div class="main-padding">
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.REGISTER_USER' | translate }}</h3>
  <section class="content">
    <div class="card-body">
        <div class="text text-center title_margintop">
          <h3 style="text-align: center; color: #000;">{{ 'REGISTER_USER.REGISTER_COMPLETED' | translate }}</h3>
      </div>
    </div>
    <div class="card-body">
      <div class="text-center">
        <p class="card-result">{{ 'REGISTER_USER.IF_CONTINUE_REGISTER_CLICK_CONTINUE' | translate }}<br>{{ 'REGISTER_USER.IF_SEND_VERIFCD_CLICK_SEND' | translate }}</p>
      <div class="two-btn">
        <div class="display">
          <a (click)="registration()" class="btn-pink">
            <i></i> {{ 'BUTTON_TYPES.CONTINUE_REGISTRATION' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="sendVerifCd()" class="btn-pink">
            <i></i> {{ 'BUTTON_TYPES.SEND_VERIFICATION_CODE' | translate }}
          </a>
        </div>
      </div>
      <div class="text-center" style="margin-top: 50px;">
        <p class="card-title">{{ 'REGISTER_USER.CAN_SEND_AUTH_CODE1' | translate }}<a (click)="search()" class="text2">{{ 'BUTTON_TYPES.REGISTRATION_LIST' | translate }}</a>{{ 'REGISTER_USER.CAN_SEND_AUTH_CODE2' | translate }}</p>
      </div>
    </div>
    </div>
    <!-- BUTTON end -->
  </section>
</div>
