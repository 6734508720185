import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bulk-register',
  templateUrl: './bulk-regist-fail-list.component.html',
  styleUrls: ['./bulk-regist-fail-list.component.scss'],
})
export class BulkRegisterFailListComponent  implements OnInit {
  public error_data = [];
  public code = '';

  constructor(private router: Router,
    public translate: TranslateService,
    public data: DataProvider,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) {}
    
  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-bulk-regist';
    this.code = this.route.snapshot.paramMap.get('code');
    if (this.code == 'EF00809' || this.code == 'EF00810') {
      this.translate.get('REGISTER_ALL_USER_FAIL.FAILED_TO_BATCH_USER_REGISTRATION').subscribe(
        (res) => {
          document.getElementById('title').innerHTML = res;
        }
      );
    }
    this.error_data = JSON.parse(sessionStorage.getItem(this.code)).data.error_data;
    sessionStorage.clear();
  }
  
  back(){
    this.router.navigate(['/contract-user-bulk-regist']);
  }
  
  changeuserinfo(){
    this.router.navigate(['/contract-user-list']);
  }
}
