import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppService } from 'src/app/utils/services/app.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { DatePipe } from '@angular/common';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-mail-edit',
  templateUrl: './mail-edit.component.html',
  styleUrls: ['./mail-edit.component.scss'],
})
export class MailEditComponent implements OnInit {
  public New_Mail;
  public today;
  public app_date;
  public app_time;
  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private router: Router,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    public data: DataProvider,
    protected datepipe: DatePipe,
    public rest: CallapiService,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-mail-reference';
  }

  dataValidation() {
    let err = false;
    let errTxt = '';
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (this.New_Mail == null || this.New_Mail == '' || this.New_Mail == undefined) {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_MAIL_ADDRESS')
        .subscribe((res) => (errTxt = res));
    }else if (this.New_Mail != "" && (this.New_Mail.length <= 5 || !EMAIL_REGEXP.test(this.New_Mail))) {
      err = true;
      this.translate
        .get('MEMBER_VALIDATION.ERROR_MAIL_ADDRESS')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  
  change() {
    this.spinner.show();
    if (!this.dataValidation()) {
      this.spinner.hide();
      return true;
    } else {
      this.today = new Date();
      this.app_date = this.datepipe.transform(this.today, 'yyyyMMddHHmmss');
      const body = {
        data:{
            user_uuid: this.data.uuid,
            uuid:  this.data.uuid,
            new_email: this.New_Mail,
            time: this.app_date
        }
      };
      console_log(body)
      this.rest.Send_MailChangeMail(
        this.data.uuid, 
        this.data.uuid, 
        this.New_Mail,
        this.app_date).subscribe(
          (res) => {
            console_log(res);
            this.spinner.hide();
            this.router.navigate(['/contract-mail-sended']);
          },(err) => {
            console_log(err);
            this.spinner.hide();
            this.router.navigate(['/contract-error',{ code: err.error.error.code}]);
          }
        );
    }
  }
}
