import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-authmail-complete',
  templateUrl: './authmail-complete.component.html',
  styleUrls: ['./authmail-complete.component.scss'],
})
export class BulkAuthmailCompleteComponent implements OnInit {
  constructor(private router: Router,
    public data: DataProvider) {}

  ngOnInit() {
    this.data.menuPageTitle = 'batchtransmission';
  }
}
