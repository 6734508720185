import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/utils/services/app.service';

@Component({
  selector: 'app-corp-reference',
  templateUrl: './corp-reference.component.html',
  styleUrls: ['./corp-reference.component.scss'],
})
export class CorpReferenceComponent implements OnInit {
  public csvFile;
  public Corporation_No;
  public CompanyName;
  public CompanyNameKana;
  public PostCode;
  public Address1;
  public Address2;
  public Address3;
  public Telephone;  
  public Last_Name;
  public Name;
  public Last_Name_Kana;
  public Name_Kana;
  public Staff_Post
  public Staff_Position;
  public Users;
  public History_Flg;
  public Corporation_Form;
  public pdffile_name;
  public documentpdf;
  public pages1;
  public change_button;
  public CorporateStatus;
  public Acquired_Company_Code;
  public StartDate;
  public EndDate;
  public Promo_Code1;
  public Promo_Code2;
  public paid_form;
  public  health_code;
  public corporation_no = '';
  public corporation_noZ = false;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    public appService: AppService,
  ) {    
    this.appService.auth();
  }

  ngOnInit() {
    this.widthChanger('main-header', 741);
    this.data.menuPageTitle = 'contract-corp-reference';
    this.corporation_no = JSON.parse(localStorage.getItem('loginData')).data.corporation_no;
    this.spinner.show();
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        console_log(res)
        if (res.code == 'S000001') {
          this.pages1 = res.data;
          for (let i = 0; i < this.pages1.length; i++) {
            if (this.pages1[i].pagename == 'contract-corp-edit') {
              console_log('contract-corp-edit');
              if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
                this.change_button = true;
              }
              if (this.pages1[i].auth == 'D') {
                this.change_button = false;
              }
            }
          }
        }
      },
    );
    this.contractInfo();
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;
      header.style.minWidth = width.toString() + 'px';
    }
  }

  info() {
    this.router.navigate(['/contract-corp-edit']);
  }
  
  pdf_download() {
    this.rest.Download_Regcertificates(
      this.data.uuid, 
      JSON.parse(localStorage.getItem('loginData')).data.uuid
    ).subscribe(
      (res) => {
        if (res.code == 'S000001') {
          this.documentpdf = res.data.s3url;
          window.open(this.documentpdf, '_blank', '');
        }
      },
      (err) => {
        console_log(err);
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }

  contractInfo() {
    if (this.corporation_no.substring(0, 1) == 'Z') {
      this.corporation_noZ = false;
      this.rest.Get_SaleCompanyId(this.data.uuid, JSON.parse(localStorage.getItem('loginData')).data.uuid).subscribe(
        (res) => {
          console_log(res);
          this.CompanyName = res.data.company_name;
          this.CompanyNameKana = res.data.company_name_kana;
          this.PostCode = res.data.postcode;
          this.Address1 = res.data.address_1;
          this.Address2 = res.data.address_2;
          this.Address3 = res.data.address_3;
          this.Last_Name = res.data.staff_last_name;
          this.Name = res.data.staff_first_name;
          this.Last_Name_Kana = res.data.staff_last_name_kana;
          this.Name_Kana = res.data.staff_first_name_kana;
          this.Telephone = res.data.tel;
          this.Corporation_Form = res.data.corporation_form;
          this.spinner.hide();
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/contract-err', {code: err.error.error.code}]);
        }
      );
    } else {
      this.corporation_noZ = true;
      this.rest.Get_ContractsId(this.data.uuid, JSON.parse(localStorage.getItem('loginData')).data.uuid).subscribe(
        (res) => {
          console_log(res);
          this.Corporation_No = res.data.corporation_no;
          this.CompanyName = res.data.company_name;
          this.CompanyNameKana = res.data.company_name_kana;
          this.PostCode = res.data.postcode;
          this.Address1 = res.data.address_1;
          this.Address2 = res.data.address_2;
          this.Address3 = res.data.address_3;
          this.Last_Name = res.data.staff_last_name;
          this.Name = res.data.staff_first_name;
          this.Last_Name_Kana = res.data.staff_last_name_kana;
          this.Name_Kana = res.data.staff_first_name_kana;
          this.Staff_Post = res.data.staff_post;
          this.Staff_Position = res.data.staff_position;
          this.Telephone = res.data.tel;
          this.Users = res.data.users;
          this.History_Flg = res.data.history_flg;
          this.Corporation_Form = res.data.corporation_form;
          this.CorporateStatus = res.data.corporate_status;
          this.health_code = res.data.health_code;
          this.Promo_Code1 = res.data.promotion_code1;
          this.Promo_Code2 = res.data.promotion_code2;
          this.paid_form = res.data.paid_form;
          this.spinner.hide();
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/contract-err', {code: err.error.error.code}]);
        }
      );
    }
  }
}
declare global {
  interface Window {
    IMAGE_RESULT?: string;
  }
}
