<div class="main-padding">
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.MAIL_ADDRESS' | translate }}</h3>
  <section class="content" id="info">
    <div class="card-body" >
      <div class="text-center" >
        <h3 class="title-result">{{ 'MY_ACCOUNT.SENT_VERIFICATION_MAIL_TITLE_1' | translate }}<br>{{ 'MY_ACCOUNT.SENT_VERIFICATION_MAIL_TITLE_2' | translate }}</h3>
        <p class="card-result">{{ 'MY_ACCOUNT.SENT_VERIFICATION_MAIL_TEXT_1' | translate }}<br>{{ 'MY_ACCOUNT.SENT_VERIFICATION_MAIL_TEXT_2' | translate }}</p>
      </div>
    </div>
  </section>
</div>