<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'CODE_APPLY.TITLE' | translate }}</h3>  
  <!-- Section №1 -->
  <div id="edit">
    <div class="card-body">
      <p class="left-border header-text2">{{ 'CODE_APPLY.PURCHASE_REQUISITION' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body">
      <a (click)="pdf()" class="btn-link">{{ 'CODE_APPLY.PDF_URL' | translate }}</a>
    </div>
    <div class="card-body">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="check"
              [(ngModel)]="check"
            />
            <label for="check" class="custom-control-label ftext">{{'CODE_APPLY.TERMS' | translate}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <p class="card-title">{{ 'CODE_APPLY.1DAYPASS_PURCHASED_TICKETS' | translate }}</p>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <section class="content">
        <div class="container-fluid">
          <div class="row" style="max-width: 1000px;">
            <table class="table item-table">
              <thead>
                <tr class="text-center">
                  <th>{{ 'CODE_APPLY.TH_PRODUCT_NAME' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_UNIT_PRICE' | translate }} <span>{{ 'CODE_APPLY.TAX' | translate }}</span></th>
                  <th>{{ 'CODE_APPLY.TH_PIECES' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_SUBTOTAL' | translate }} <span>{{ 'CODE_APPLY.TAX' | translate }}</span></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of Products; let i = index" class="text-center">
                  <td>{{ item.product_name }}</td>
                  <td>{{ parse(item.unit_price_tax) }}</td>
                  <td>
                    <div class="custom-control display-flex">
                      <select class="form-control sold-input" (change)="onInput($event, i, item)">
                        <option *ngFor="let quantity of getQuantityLimitArr(item.quantity_limit);" [value]="quantity">
                          {{quantity}}
                        </option>
                      </select>
                      <span>{{getQuantityName(item.set_flg)}}</span>
                    </div>
                  </td>
                  <td>{{parse(SubTotals[i])}}</td>
                </tr>
              </tbody>
            </table>
            <div class="total">
              <div class="total-piece">
                <h3 class="card-title"> {{ 'CODE_APPLY.TOTAL_PURCHASE_QUANTITY' | translate }}</h3>
                <h3 class="card-title"><span>{{totalPurchase}}{{ 'CODE_APPLY.PIECES' | translate }}</span></h3>
              </div>
              <div class="total-amount">
                <h3 class="card-title"> {{ 'CODE_APPLY.TOTAL_AMOUNT' | translate }}{{ 'CODE_APPLY.TAX' | translate }}</h3>
                <h3 class="card-title"><span>{{parse(totalAmount)}}</span></h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Button -->
    <div class="text-center">
      <!-- Error text -->
      <div class="text1 text-center" style="height: 34px">
        <p style="display: none;" id="error"></p>
      </div>
      <div class="two-btn">
        <div class="display">
          <a (click)="Next()" class="btn-log"[ngClass]="{'btn-log-disable': !check}">
            {{ 'CODE_APPLY.CONFIRM_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- Button end -->
  </div>
  <!-- End Section №1 -->
   
  <!-- Section №2 -->
  <section class="content" id="confirm" style="display: none;">
    <div class="card-body">
      <p class="left-border header-text2">{{ 'CODE_APPLY.CONFIRM' | translate }}</p>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'CODE_APPLY.1DAYPASS_PURCHASED_CONFIRM' | translate }}</h3>
        </div>
        <!-- <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
            <h3 class="card-title">{{total_ticket_num}}{{ 'CODE_APPLY.PIECES' | translate }}</h3>
        </div> -->
      </div>
    </div>
    <div class="card-body">
      <section class="content">
        <div class="container-fluid">
          <div class="row" style="max-width: 1000px;">
            <table class="table item-table confirm-table">
              <thead>
                <tr class="text-center">
                  <th>{{ 'CODE_APPLY.TH_PRODUCT_NAME' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_UNIT_PRICE' | translate }} <span>{{ 'CODE_APPLY.TAX' | translate }}</span></th>
                  <th>{{ 'CODE_APPLY.TH_PIECES' | translate }}</th>
                  <th>{{ 'CODE_APPLY.TH_SUBTOTAL' | translate }} <span>{{ 'CODE_APPLY.TAX' | translate }}</span></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ProductsConfirm; let i = index" class="text-center">
                  <td>{{ item.product_name }}</td>
                  <td>{{ parse(item.bill_unit_price_tax) }}</td>
                  <td>{{ item.value}}{{getQuantityName(item.set_flg)}}</td>
                  <td>{{ parse(item.bill_tax) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="total">
              <div class="total-piece">
                <h3 class="card-title"> {{ 'CODE_APPLY.TOTAL_PURCHASE_QUANTITY' | translate }}</h3>
                <h3 class="card-title"><span>{{totalPurchase}} <span>{{ 'CODE_APPLY.PIECES' | translate }}</span> </span></h3>
              </div>
              <div class="total-amount">
                <h3 class="card-title"> {{ 'CODE_APPLY.TOTAL_AMOUNT' | translate }}{{ 'CODE_APPLY.TAX' | translate }}</h3>
                <h3 class="card-title" style="padding-right: 0px;"><span>{{parse(total_bill_tax)}}</span></h3>
                <!-- <h3 class="card-title bill-tax">(税込{{parse(total_bill_tax)}})</h3> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Button -->
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back()" class="btn-grey">{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="apply()" class="btn-pink">{{ 'CODE_APPLY.PURCHASE_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- Button end -->
  </section>
  <!-- End Section №2 -->
</div>
<!-- Loader -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#E10800 " type="square-jelly-box" [fullScreen]="true">
  <p style="color: #E10800 "> {{ 'COMMON.LOADING' | translate }} </p>
</ngx-spinner>