import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-authmail',
  templateUrl: './unpaid-payment.component.html',
  styleUrls: ['./unpaid-payment.component.scss'],
})
export class UnpaidPaymentComponent implements OnInit {
  public AcquiredCompanyName = '';
  public customCheckbox1: boolean = true;
  public customCheckbox2: boolean = false;

  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  constructor(private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-unpaid-payment';
    this.spinner.show();
    const body = {
      data:{
        user_uuid: this.data.uuid,
        corporation_no: JSON.parse(localStorage.getItem('loginData')).data.corporation_no
      }
    };
    console_log(body);
    this.rest.Get_UnpaidUser(this.data.uuid, JSON.parse(localStorage.getItem('loginData')).data.corporation_no).subscribe(
      (res) => {
        console_log(res);
        this.AcquiredCompanyName = res.company_name;
        this.filteredItems = res.data.unpaid_list;
        this.filteredItems = []
        if (this.customCheckbox1 && !this.customCheckbox2) {
          res.data.unpaid_list.forEach(element => {
            if (element.unpaid_status.toUpperCase().indexOf('未払'.toUpperCase()) >= 0) {
              this.filteredItems.push(element);
            }
          });
        } else if (!this.customCheckbox1 && this.customCheckbox2) {
          res.data.unpaid_list.forEach(element => {
            if (element.unpaid_status.toUpperCase().indexOf('支払済'.toUpperCase()) >= 0) {
              this.filteredItems.push(element);
            }
          });
        } else {
          this.filteredItems = res.data.unpaid_list;
        }
        this.pageSize = this.filteredItems.length;
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    )
  }
  // details(){
  //   this.router.navigate(['/contract-unpaid-payment-conf']);
  // }
  details(casio_id, user_no, user_name, unpaid_amount, end_date) {
    this.router.navigate(['/contract-unpaid-payment-conf', { 
        casio_id: casio_id,
        user_no: user_no,
        user_name: user_name,
        unpaid_amount: unpaid_amount,
        end_date: end_date,
      }]
    );
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }
  FilterUnpaidStatus() {
    this.spinner.show();
    this.rest.Get_UnpaidUser(this.data.uuid, JSON.parse(localStorage.getItem('loginData')).data.corporation_no).subscribe(
      (res) => {
        this.filteredItems = res.data.unpaid_list;
        this.filteredItems = [];
        if (this.customCheckbox1 && !this.customCheckbox2) {
          res.data.unpaid_list.forEach(element => {
            if (element.unpaid_status.toUpperCase().indexOf('未払'.toUpperCase()) >= 0) {
              this.filteredItems.push(element);
            }
          });
        } else if (!this.customCheckbox1 && this.customCheckbox2) {
          res.data.unpaid_list.forEach(element => {
            if (element.unpaid_status.toUpperCase().indexOf('支払済'.toUpperCase()) >= 0) {
              this.filteredItems.push(element);
            }
          });
        } else {
          this.filteredItems = res.data.unpaid_list;
        }
        console_log(this.filteredItems);
        this.pageSize = this.filteredItems.length;
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }
}
