import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-unpaid-payment-comp',
  templateUrl: './unpaid-payment-comp.component.html',
  styleUrls: ['./unpaid-payment-comp.component.scss'],
})
export class UnpaidPaymentCompleteComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    private router: Router,
    public data: DataProvider,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-unpaid-payment';
  }
  back(){
    this.router.navigate(['/contract-unpaid-payment']);
  }
}
