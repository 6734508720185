<div class="main-padding">
  <h3 class="header-bottom">
    {{ 'SIDEBAR_MENU.REGISTER_ALL_USER' | translate }}
  </h3>

  <section class="content" id="infoedit" style="display: block">
    <div class="card-body">
      <div class="text-center">
        <h3 style="margin-top: 90px; text-align: center">
          {{ 'REGISTER_ALL_USER_FAIL.FAILED_TO_BATCH_USER_REGISTRATION' | translate }}
        </h3>
      </div>
    </div>
    <div class="card-body">
      <div class="text-center">
        <p id="err_text" class="card-result">
          {{ 'REGISTER_ALL_USER_FAIL.PLEASE_CHECK_THE_UPLOADED_CSV_FILE_AND_RE_UPLOAD' | translate }}
        </p>
      </div>
    </div>
    <!-- BUTTON -->
    <div class="text-center button_margin_bottom">
      <div class="col-12 ">
        <div class="display">
          <a (click)="back()" class="btn-grey">
            <i></i> {{ 'REGISTER_ALL_USER_FAIL.RETURN_TO_BATCH_USER_REGISTRATION' | translate }}
          </a>
        </div>
      </div>
    </div>
  </section>
</div>
