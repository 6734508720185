import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public sidebarMenuOpened = true;
  @ViewChild('contentWrapper', { static: false }) contentWrapper;

  constructor(private renderer: Renderer2, 
    public data: DataProvider) {}

  ngOnInit() {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
    this.renderer.removeClass(document.querySelector('app-root'), 'change-password-page');
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.data.sidebarMenuOpened = false;
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      document.getElementById('sidebar-overlay').style.display = 'none';
    }
  }

  mainSidebarHeight(height) {
    // this.renderer.setStyle(
    //   this.contentWrapper.nativeElement,
    //   'min-height',
    //   height - 114 + 'px'
    // );
  }

  onResize(event) {
    if(event.target.innerWidth <= 991) {
      this.data.sidebarMenuOpened = false;
      document.getElementById('main-sidebar').style.transform = 'translate(-250px, 0)';
    } else {      
      document.getElementById('main-sidebar').style.transform = 'none';
      if(this.data.sidebarMenuOpened) {
        document.getElementById('sideMenu1').classList.remove('fases');
        document.getElementById('sideMenu1').classList.add('fasas');
      } else {
        document.getElementById('sideMenu1').classList.remove('fasas');
        document.getElementById('sideMenu1').classList.add('fases');
      }      
    }
  }

  toggleMenuSidebar() {
    if (this.data.sidebarMenuOpened) {
      document.getElementById('main-sidebar').style.transform = 'translate(-250px, 0)';
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.data.sidebarMenuOpened = false;
      document.getElementById('sidebar-overlay').style.display = 'none';
    } else {      
      document.getElementById('main-sidebar').style.transform = 'none';
      this.renderer.removeClass(
        document.querySelector('aside'),
        'transform'
      );
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.data.sidebarMenuOpened = true;
      document.getElementById('sidebar-overlay').style.display = 'block';
    }
  }
}
