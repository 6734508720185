import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss'],
})
export class RegisterCompleteComponent implements OnInit {
  public User_Code;
  constructor(
    private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-regist';
    this.User_Code = this.route.snapshot.paramMap.get('user_code');
  }
  registration(){
    this.router.navigate(['/contract-user-regist']);
  }
  sendVerifCd(){
    this.router.navigate(['/contract-user-regist-authmail-conf', {user_code: this.User_Code}]);
  }
  search(){
    this.router.navigate(['/contract-user-list']);
  }
}
