<div class="main-padding">
  <!-- <div class="scroll-thumb" style="overflow-x: scroll;overflow-y: hidden;z-index: 1000;bottom:0;"></div> -->
  <h3 class="header-bottom">{{ 'SIDEBAR_MENU.COMPANY_INFO' | translate }}</h3>

  <!-- Section 1 -->
  <section class="content" id="info">
    <div class="card-body">
      <p class="left-border">{{'COMPANY_INFO.COMPANY_INFO_REFERENCE' | translate }}</p>
      <p class="card-title">{{'COMPANY_INFO.REGISTERED_INFORMATION_MSG' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body" *ngIf="corporation_noZ">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title"> {{'DETAILS.COMPANY_NUMBER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-6	col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title"> {{ Corporation_No }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body"  *ngIf="(Corporation_Form == '1' || Corporation_Form == '2') && corporation_noZ">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title"> {{'DETAILS.PROMO_CODE' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-6	col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title"> {{ Promo_Code1 }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body"  *ngIf="Corporation_Form == '3' && corporation_noZ">
      <div class="row detail">
        <div class="col-12 col-sm-5    col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title"> {{'DETAILS.PROMO_CODE' | translate }} ① </h3>
        </div>
        <div class="col-12 col-sm-6    col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8    col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title"> {{ Promo_Code1 }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row detail">
        <div class="col-12 col-sm-5    col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title"> {{'DETAILS.PROMO_CODE' | translate }} ②</h3>
        </div>
        <div class="col-12 col-sm-6    col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8    col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title"> {{ Promo_Code2 }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 3 -->
    <div class="card-body">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title">{{'DETAILS.COMPANY_NAME_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ CompanyName }}<br>{{CompanyNameKana}} </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 4 -->
    <div class="card-body">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title">{{'DETAILS.ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-12	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{PostCode}}<br>{{Address1}}<br>{{Address2}}<br>{{Address3}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 5 -->
    <div class="card-body">
      <div class="row detail">
        <div class="col-11 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title">{{'DETAILS.TELEPHONE_DEST' | translate }}</h3>
        </div>
        <div class="col-11 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">

            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Telephone }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 6 -->
    <div class="card-body" *ngIf="Corporation_Form != '3' && corporation_noZ">
      <div class="row detail">
        <div class="col-11 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title">{{'DETAILS.INSURANCE_CARD' | translate }}</h3>
        </div>
        <div class="col-11 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">

            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ health_code }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 7 -->
    <div class="card-body">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title"> {{ 'DETAILS.CHARGER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">{{ Name }}</h3>
            </div>
          </div>
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name_Kana }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 8 -->
    <div class="card-body" *ngIf="corporation_noZ">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title">{{'DETAILS.DEPARTMENT_POSITION_DEST' | translate}}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8" *ngIf = "Staff_Post != '' &&  Staff_Position != ''">
              <h3 class="card-title display">{{ Staff_Post }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ Staff_Position }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 9 -->
    <div class="card-body" *ngIf="corporation_noZ">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title">{{'CONTRACT.NUMBER_OF_USERS_DEST' | translate}}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Users }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 10 -->
    <div class="card-body" *ngIf="corporation_noZ">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title">{{'DETAILS.USAGE_HISTORY_POSITION_DEST' | translate}}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="History_Flg == '1'">
                {{ 'COMPANY_INFO.USE_USED_HISTORY' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="History_Flg == '0'">
                {{ 'COMPANY_INFO.DONT_USE_USED_HISTORY' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11 -->
    <div class="card-body">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title"> {{'DETAILS.USAGE_FORM_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Corporation_Form == '1'">
                {{ 'COMPANY_INFO.CONTRACTED_COMPANY_EMPLOYEE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '2'">
                {{ 'COMPANY_INFO.HEALTH_INSURANCE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '3'">
                {{ 'COMPANY_INFO.WElFARE' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 12 -->
    <div class="card-body" *ngIf="corporation_noZ">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title"> {{'DETAILS.MEMBERSHIP_FEE_BURDEN' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="paid_form == '3'">
                {{ 'DETAILS.COMPANY_REGISTER_PERSONAL_BURDEN' | translate }}
              </h3>
              <h3 class="card-title marginLeft" *ngIf="paid_form == 2">
                {{ 'DETAILS.COMPANY_REGISTER_CORPORATE_BURDEN_GO' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="paid_form == '1'">
                {{ 'DETAILS.COMPANY_REGISTER_CORPORATE_BURDEN' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 13 -->
    <div class="card-body" *ngIf="corporation_noZ">
      <div class="row detail">
        <div class="col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 col-dest">
          <h3 class="card-title">{{'COMPANY_INFO.REGISTRATION_COPY' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-dest1">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <a class="btn-pdf" (click)="pdf_download()">
                <i class="nav-icon whiteI-file"></i>{{'BUTTON_TYPES.REGISTRATION_COPY_PDF' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Button -->
    <div class="text1 text-center" *ngIf="change_button && corporation_noZ">
      <a (click)="info()" class="btn-pink btn-top">
        <i></i> {{'BUTTON_TYPES.CHANGE' | translate }}
      </a>
    </div>
    <!-- button end -->
  </section>
</div>
