export const environment = {
  production: false
};

export const IDENTITY_POOL_ID = 'ap-northeast-2:310e6713-5d6a-41cb-9391-2c8750c1645f';
export const USER_POOL_ID = 'ap-northeast-2_oM73VQAMU';
export const CLIENT_ID = '3qjcenqj4f6sg8l54qe8aii8vu';
export const REGION = 'ap-northeast-2';
export const MANUAL_PDF_URL = 'https://test-uconsole.joyfit-service.jp/pdf/unconsole_manual.pdf';
export const MANUAL_SALE_PDF_URL = 'https://test-uconsole.joyfit-service.jp/pdf/unconsole_manual_sale.pdf';
export const TERMS_PDF_URL = 'https://test-uconsole.joyfit-service.jp/pdf/notes.pdf';
export const API_URL = 'https://e92f786122.execute-api.ap-northeast-2.amazonaws.com/test';
export const S3_BUCKET = 'test-uconsole.joyfit-service.jp';
export const TERMS_1DAYPASS= 'https://test-open-s3.s3.ap-northeast-2.amazonaws.com/pdf/1daypass_terms.pdf'