import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Output,
  EventEmitter,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Console } from 'console';
import { $ } from 'protractor';
import { AppService } from 'src/app/utils/services/app.service';
import { console_log, DataProvider } from 'src/providers/data/data';
import { platformBrowser } from '@angular/platform-browser';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  public sidebarMenuOpened = true;
  public applyOpened = false;

  public treeOpened = false;
  public pages;
  public UUID;
  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public appService: AppService,
    private renderer: Renderer2,
    public data: DataProvider,
    public rest: CallapiService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        if (res.code == 'S000001') {
          this.pages = res.data;
          for (let i = 0; i < this.pages.length; i++) {
            if (this.pages[i].pagename == 'contract-home') {
              this.data.menuHomePage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuHome = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuHome = false;
              }
            }
            if (this.pages[i].pagename == 'contract-corp-reference') {
              this.data.menuContractCorpPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuContractCorp = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuContractCorp = false;
              }
            }
            if (this.pages[i].pagename == 'contract-mail-reference') {
              this.data.menuContractMailPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuContractMail = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuContractMail = false;
              }
            }
            if (this.pages[i].pagename == 'contract-user-regist') {
              this.data.menuContractUserPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuContractUser = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuContractUser = false;
              }
            }
            if (this.pages[i].pagename == 'contract-user-bulk-regist') {
              this.data.menuContractUserBulkPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuContractUserBulk = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuContractUserBulk = false;
              }
            }
            if (this.pages[i].pagename == 'contract-user-list') {
              this.data.menuContractUserListPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuContractUserList = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuContractUserList = false;
              }
            }
            if (this.pages[i].pagename == 'contract-bulk-authmail') {
              this.data.menuContractBulkPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuContractBulk = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuContractBulk = false;
              }
            }
            if (this.pages[i].pagename == 'contract-unpaid-payment') {
              this.data.menuContractUnpaidPage = this.pages[i].pagename;
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuContractUnpaid = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuContractUnpaid = false;
              }
            }
            if (this.pages[i].pagename == 'contract-order-apply') {
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuOrderApply = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuOrderApply = false;
              }
            }
            if (this.pages[i].pagename == 'contract-order-list') {
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuOrderList = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuOrderList = false;
              }
            }
            if (this.pages[i].pagename == 'contract-code-list') {
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuCodelist = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuCodelist = false;
              }
            }
            if (this.pages[i].pagename == 'contract-ticket-list') {
              if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
                this.data.menuTicketlist = true;
              }
              if (this.pages[i].auth == 'D') {
                this.data.menuTicketlist = false;
              }
            }
          }
          this.spinner.hide();
        }
      },
      (err_scr_auth) => {
        console_log(err_scr_auth);
        window.localStorage.clear();
        this.router.navigate([
          '/contract-error',
          { code: err_scr_auth.error.error.code, id: this.data.uuid },
        ]);
        this.spinner.hide();
      }
    );

    if (this.sidebarMenuOpened) {
      this.renderer.removeClass(
        document.querySelector('aside'),
        'collapse-width'
      );
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.sidebarMenuOpened = true;
      document.getElementById('sideMenu1').classList.add('arrow');
      document.getElementById('sideMenu1').classList.remove('arrow1');
    } else {
      this.renderer.addClass(document.querySelector('aside'), 'collapse-width');
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.sidebarMenuOpened = false;
      document.getElementById('sideMenu1').classList.remove('arrow');
      document.getElementById('sideMenu1').classList.add('arrow1');
    }

    this.spinner.hide();
  }

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }

  toggleMenuSidebar() {
    if (this.sidebarMenuOpened) {
      this.renderer.addClass(document.querySelector('aside'), 'collapse-width');
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.sidebarMenuOpened = false;
      document.getElementById('sideMenu1').classList.remove('arrow');
      document.getElementById('sideMenu1').classList.add('arrow1');
      document.getElementById('dropdownlist1').classList.add('dropdownlist1');
      document.getElementById('dropdownlist2').classList.add('dropdownlist1');
      document.getElementById('dropdownlist3').classList.add('dropdownlist1');
      document.getElementById('dropdownlist4').classList.add('dropdownlist1');
      // document.getElementById('dropdownlist5').classList.add('dropdownlist1');
      // document.getElementById('dropdownlist6').classList.add('dropdownlist1');
      // document.getElementById('dropdownlist7').classList.add('dropdownlist1');
      // document.getElementById('dropdownlist8').classList.add('dropdownlist1');
    } else {
      this.renderer.removeClass(
        document.querySelector('aside'),
        'collapse-width'
      );
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.sidebarMenuOpened = true;
      document.getElementById('sideMenu1').classList.add('arrow');
      document.getElementById('sideMenu1').classList.remove('arrow1');
      document.getElementById('dropdownlist1').classList.remove('dropdownlist1');
      document.getElementById('dropdownlist2').classList.remove('dropdownlist1');
      document.getElementById('dropdownlist3').classList.remove('dropdownlist1');
      document.getElementById('dropdownlist4').classList.remove('dropdownlist1');
      // document.getElementById('dropdownlist5').classList.remove('dropdownlist1');
      // document.getElementById('dropdownlist6').classList.remove('dropdownlist1');
      // document.getElementById('dropdownlist7').classList.remove('dropdownlist1');
      // document.getElementById('dropdownlist8').classList.remove('dropdownlist1');
    }
  }

  checkMenu() {
    this.appService.auth();
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.renderer.addClass(document.querySelector('aside'), 'transform');
      document.getElementById('sidebar-overlay').style.display = 'none';
      this.data.sidebarMenuOpened = false;
    }
    if(this.data.menuPageTitle != 'contract-user-list'){
      sessionStorage.removeItem('searchData');
    }
    if (document.getElementById('applyView'))
      document.getElementById('applyView').style.display = 'none';
    this.applyOpened = false;
  }
  collapseApply() {
    if (!this.applyOpened) {
      this.applyOpened = true;
      document.getElementById('applyView').style.display = 'block';
      document.getElementById('apply1').classList.remove('active');
      document.getElementById('apply1').classList.remove('nav-link');
      document.getElementById('apply1').classList.add('nav-link-new');
      document.getElementById('apply1-text').classList.add('drop1');
    } else {
      this.applyOpened = false;
      document.getElementById('applyView').style.display = 'none';
      document.getElementById('apply1').classList.remove('active');
      document.getElementById('apply1').classList.add('nav-link');
      document.getElementById('apply1').classList.remove('nav-link-new');
      document.getElementById('apply1-text').classList.remove('drop1');
    }
  }
  checkMenuDropDownList(id) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      );
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      );
      this.renderer.addClass(document.querySelector('aside'), 'transform');
      document.getElementById('sidebar-overlay').style.display = 'none';
      this.data.sidebarMenuOpened = false;
    }
  }
  logout() {
    this.appService.logout();
  }
}
