<div class="main-padding">
  <h3 class="header-bottom">{{ 'ALL_VERIFICATION_CODE_SEND.TITLE_TEXT' | translate }}</h3>
  <section class="content" id="sentverificationcd">
    <div class="title_margintop">
      <h3 style="color: black; text-align: center;">{{ 'ALL_VERIFICATION_CODE_SEND.ALL_VERIFICATION_CODE_SENT' | translate }}</h3>
    </div>
    <div class="text-center">
      <p class="card-result" style="margin-top: 40px;">{{ 'ALL_VERIFICATION_CODE_SEND.MESSAGE_SHOW_HERE' | translate }}</p>
    </div>
  </section>
</div>