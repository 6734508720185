import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-list-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class ListDeleteComponent implements OnInit {

  public User_Code;
  public User_Last_Name;
  public User_First_Name;
  public Mail_Address;
  public Number;
  public Use_Status;
  public Confirm_Flg;
  public Under = false;
  public Type;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.data.menuPageTitle = 'contract-user-list';
    this.User_Code = this.route.snapshot.paramMap.get('user_code');
    this.spinner.show();
    var type = JSON.parse(localStorage.getItem('loginData')).data.corporation_no.split('');
    this.Type = type[0];
    this.rest.Get_MembersId(this.data.uuid, this.User_Code).subscribe(
      (res)=>{
        console_log(res);
        this.User_Last_Name = res.data.user_last_name;
        this.User_First_Name = res.data.user_first_name;
        this.Mail_Address = res.data.mail_address;
        this.Number = res.data.user_no;
        this.Use_Status = res.data.use_status;
        this.Confirm_Flg = res.data.confirm_flg;
        this.spinner.hide();
      },
      (err)=>{
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/contract-err', {code: err.error.error.code}]);
      }
    );
  }
  back(){
    this.router.navigate(['/contract-user-list-detail',{user_code: this.User_Code}]);
  }
  Delete(){
    if(this.Under){
      this.spinner.show();
      this.rest.Delete_Members(this.data.uuid, this.User_Code).subscribe(
        (res)=>{
          console_log(res);
          document.getElementById('userdeleteinfo').style.display='none';
          document.getElementById('userdeletefinish').style.display='block';
          this.spinner.hide();
        },
        (err)=>{
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/contract-err', {code: err.error.error.code}]);
        }
      );
    }
  }
  list(){
    this.router.navigate(['/contract-user-list']);
  }
}
